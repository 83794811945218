import React, { Component } from 'react';
// import logo from './logo.svg';
import pic from './urry.svg'
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={pic} className="App-logo" alt="logo" />
          <p>
            =urry!
          </p>
          {/*<a*/}
        </header>
      </div>
    );
  }
}

export default App;
